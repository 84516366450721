<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/centerService'
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  created() {
    service.getMeta().then((meta) => {

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        helpers.createLocalityValidations(validationSchema, translate)

        return yup.object().shape(validationSchema, ['latitude', 'longitude'])
      }
      this.metadata = meta
      const fieldsById = _.keyBy(this.metadata.fields, (f) => f.id)

      fieldsById.centerType.onValueChange = ( changedValue, fields ) => {
        if (changedValue?.value === 5) {
          fields.port.hidden = false
          //fields.port.value.value = null
        } else {
          fields.port.hidden = true
          fields.port.value = null
        }
      }
    })
  }
}
</script>
